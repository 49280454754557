
.triangle {
    text-align:center;
    width:0;
    height:0;
    margin: 0;
    color:white;
    position:relative;
    text-transform: uppercase;
}

.triangle-bottomright {
    border-bottom: 80px solid #353954;
    border-left: 80px solid transparent;
    line-height: 72px;
}

.triangle-topleft {
    border-top: 80px solid #353954;
    border-right: 80px solid transparent;
    line-height:0px;
}

.text {
    position: absolute;
    right: 100%;
    width: 45px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
}

.triangle-bottomright .text {
    top: 12px;
}

.triangle-topleft .text {
    left: 100%;
    top: -50px;
}

.square {
    width: 80px;
    height: 80px;
    background:#353954;
    font-size: 1.5rem;
    text-align: center;
    font-weight: bold;
    line-height: 80px;
}

.clickable-arrow {
    font-size: 2.5rem;
    color: #682ABF;
    cursor: pointer;
    border: 1px solid #682ABF;
    padding:10px;
    border-radius: 10px;
}

.clickable-arrow:hover {
    color: #8456F7;
}