.app {
  margin: auto;
  padding: 2rem 3rem;
}

.connected-address {
  display: inline-flex;
  align-items: center;
}

.logo {
  fill: transparent;
  stroke: rgba(132, 86, 247, 0.8);
  stroke-width: 26px;
  stroke-linecap: round;
}

button.tab[aria-selected="true"] {
  border-bottom-color: #1F2237;
}

button.tab[aria-selected="false"] {
  color: #fff9;
}

@media (min-width: 2560px) {
  .app {
    padding: 2rem 12.5%
  }
}

@media (min-width: 3840px) {
  .app {
    padding: 2rem 25%
  }
}